"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageEditingActiveStatuses = exports.isTypeMultiValue = exports.multiValueEditTypes = exports.editPriorityValue = exports.EditJobSource = exports.EditJobPriority = exports.Products = exports.ActionCategory = exports.EditJobStatus = exports.EditDataType = void 0;
__exportStar(require("./download"), exports);
var EditDataType;
(function (EditDataType) {
    EditDataType["LONGREFS"] = "Image Long Refs";
    EditDataType["REFS"] = "Image Refs";
    EditDataType["AIMSJOB"] = "Aims Job Number";
})(EditDataType = exports.EditDataType || (exports.EditDataType = {}));
var EditJobStatus;
(function (EditJobStatus) {
    EditJobStatus["HOLD"] = "On Hold";
    EditJobStatus["CHECKING_IMAGES"] = "Checking Images";
    EditJobStatus["UNASSIGNED"] = "Unassigned";
    EditJobStatus["ASSGINED"] = "Assigned";
    EditJobStatus["IN_PROGRESS"] = "In Progress";
    EditJobStatus["ERROR"] = "Errored";
    EditJobStatus["COMPLETE"] = "Complete";
})(EditJobStatus = exports.EditJobStatus || (exports.EditJobStatus = {}));
// Action Column
var ActionCategory;
(function (ActionCategory) {
    ActionCategory["ARTWORK_UPLOAD"] = "Artwork/Image Upload";
    ActionCategory["BACKGROUND"] = "B/G Damage";
    ActionCategory["BACKGROUND_SWAP"] = "B/G Swap";
    ActionCategory["BESPOKE"] = "Bespoke";
    ActionCategory["CLOTHING"] = "Clothing";
    ActionCategory["CMOS"] = "CMOS";
    ActionCategory["COSMETIC"] = "Cosmetic";
    ActionCategory["DOWNLOAD"] = "Download";
    ActionCategory["EXPOSURE"] = "Exposure";
    ActionCategory["FALL_OFF"] = "Fall off";
    ActionCategory["FOOD"] = "Food/Marks";
    ActionCategory["FRAMING"] = "Framing/Crop/Resize";
    ActionCategory["HAZE"] = "Haze";
    ActionCategory["HEAD_SWAPS"] = "Head/Eye Swaps";
    ActionCategory["INSERT_REMOVAL"] = "Insert/Removal";
    ActionCategory["PRODUCTION"] = "Production";
    ActionCategory["REFLECTION"] = "Reflection";
    ActionCategory["REFRACTION"] = "Refraction";
    ActionCategory["ROT"] = "Rotation";
    ActionCategory["SHARPENING"] = "Sharpening";
    ActionCategory["SHINE"] = "Shine";
    ActionCategory["SNOTTY"] = "Snotty Nose";
    ActionCategory["OTHER"] = "Other";
    ActionCategory["NONE"] = "None";
})(ActionCategory = exports.ActionCategory || (exports.ActionCategory = {}));
// Products Column
var Products;
(function (Products) {
    Products["BESPOKE"] = "Bespoke";
    Products["CLASSIC_GROUP"] = "Classic Group - 10x7";
    Products["COMPOSITE"] = "Composite";
    Products["EPSON"] = "Epson Printing";
    Products["GRAD"] = "Grad";
    Products["IN_THE_FRAME"] = "In The Frame";
    Products["LARGE_GROUP"] = "Large Group";
    Products["MG"] = "MG";
    Products["NONE"] = "None";
    Products["ONE_SHOT"] = "One Shot";
    Products["PORTAL"] = "Portal";
    Products["PORTRAIT"] = "Portrait";
    Products["RESEARCH"] = "Research";
    Products["SAMPLE"] = "Sample";
    Products["STUDIO_GROUP"] = "Studio Group";
    Products["STUDIO_PANO"] = "Studio Pano";
    Products["V2"] = "V2";
    Products["VISTA"] = "Vista";
})(Products = exports.Products || (exports.Products = {}));
var EditJobPriority;
(function (EditJobPriority) {
    EditJobPriority["URGENT"] = "Urgent";
    EditJobPriority["PRIORITY"] = "High Priority";
    EditJobPriority["NORMAL"] = "Normal";
})(EditJobPriority = exports.EditJobPriority || (exports.EditJobPriority = {}));
// Department Column
// Assign a colour in the UI to each of these
// search for jobSourceColour in images app/EditJobsTable.vue
// Enable Demo mode to see all colours
var EditJobSource;
(function (EditJobSource) {
    EditJobSource["AUTOMATIC"] = "Automatic";
    EditJobSource["CUSTOMER_SERVICE"] = "Customer Service";
    EditJobSource["DIRECT_ORDERES"] = "Direct Orders";
    EditJobSource["EXCEPTIONS"] = "Exceptions";
    EditJobSource["GIFTS"] = "Gifts & Goodies";
    EditJobSource["GRADUATIONS"] = "Graduations";
    EditJobSource["IMAGE_EDITING"] = "Image Editing";
    EditJobSource["MARKETING"] = "Marketing";
    EditJobSource["MILITARIY"] = "Tempest Military";
    EditJobSource["ORDER_ENTRIES"] = "Order Entry";
    EditJobSource["PACKING"] = "Packing/Finishing";
    EditJobSource["PIRBRIGHT"] = "Pirbright";
    EditJobSource["PREMIER"] = "Premier";
    EditJobSource["PRINTING"] = "Printing";
    EditJobSource["QC"] = "QC";
    EditJobSource["SALES"] = "Sales";
    EditJobSource["SANDHURST"] = "Sandhurst";
    EditJobSource["OTHER"] = "Other";
})(EditJobSource = exports.EditJobSource || (exports.EditJobSource = {}));
function editPriorityValue(p) {
    const priority = typeof p !== 'string' ? p.priority : p;
    if (priority === EditJobPriority.URGENT)
        return 3;
    if (priority === EditJobPriority.PRIORITY)
        return 2;
    return 1;
}
exports.editPriorityValue = editPriorityValue;
exports.multiValueEditTypes = [
    EditDataType.LONGREFS,
    EditDataType.REFS
];
function isTypeMultiValue(data) {
    if (typeof data === 'string') {
        return !!exports.multiValueEditTypes.find(a => a === data);
    }
    return !!exports.multiValueEditTypes.find(a => a === data.dataType);
}
exports.isTypeMultiValue = isTypeMultiValue;
exports.ImageEditingActiveStatuses = [EditJobStatus.UNASSIGNED, EditJobStatus.HOLD, EditJobStatus.CHECKING_IMAGES, EditJobStatus.IN_PROGRESS, EditJobStatus.ASSGINED, EditJobStatus.ERROR];
