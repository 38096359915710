var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import ImageDetails from './ImageDetails.vue';
import ImageComp from './Image.vue';
import _ from 'lodash';
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.imagesByClass = {};
        this.failed = false;
        this.activeImage = null;
        this.selectAllModel = false;
    }
    get selectedText() {
        const numSelect = Object.values(this.selectedImages).filter(x => x).length;
        return `Images Selected: ${numSelect} `;
    }
    get sortedImages() {
        return this.images.sort((a, b) => a.photographerCode.localeCompare(b.photographerCode) ||
            new Date(a.takeDate).getTime() - new Date(b.takeDate).getTime());
    }
    get classes() {
        return Object.entries(this.imagesByClass);
    }
    get allSelected() {
        const selectedImageCount = _.filter(this.selectedImages, x => x).length;
        if (selectedImageCount === 0) {
            return false;
        }
        if (selectedImageCount === this.images.length) {
            return true;
        }
        return null; // Indeterminate
    }
    classImage(image) {
        console.log('Image: ', image.longRef, 'clicked');
        this.activeImage = image;
    }
    isClassSelected(className) {
        const images = this.imagesByClass[className];
        const selectedClassImageCount = images.reduce((acc, image) => this.selectedImages[image.longRef] ? acc + 1 : acc, 0);
        const every = selectedClassImageCount === images.length;
        const some = selectedClassImageCount > 0;
        return every ? true : some ? null : false;
    }
    selectClass(className) {
        const val = !this.isClassSelected(className);
        this.imagesByClass[className].forEach(img => {
            Vue.set(this.selectedImages, img.longRef, val);
        });
        this.selectAllModel = this.allSelected;
        this.$emit('input', Object.keys(this.selectedImages));
    }
    isImageSelected(image) {
        return this.selectedImages[image.longRef] === true;
    }
    selectImage(image) {
        Vue.set(this.selectedImages, image.longRef, !this.selectedImages[image.longRef]);
        this.selectAllModel = this.allSelected;
        this.$emit('input', Object.keys(this.selectedImages));
    }
    selectAll(value) {
        this.images.forEach(img => {
            Vue.set(this.selectedImages, img.longRef, value);
        });
        this.$emit('input', Object.keys(this.selectedImages));
    }
    updateImagesByClass() {
        console.log('Images changed');
        const grouped = {};
        this.sortedImages.forEach(x => {
            const className = x.className || 'No Name';
            if (!grouped[className]) {
                grouped[className] = [];
            }
            grouped[className].push(x);
        });
        this.imagesByClass = grouped;
    }
};
__decorate([
    Prop({ type: Array, required: true })
], default_1.prototype, "images", void 0);
__decorate([
    Prop({ type: Object, default: () => ({ x: 200, y: 200 }) })
], default_1.prototype, "settings", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], default_1.prototype, "showSelect", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], default_1.prototype, "imageSelect", void 0);
__decorate([
    Model('', { default: {} })
], default_1.prototype, "selectedImages", void 0);
__decorate([
    Watch('images.length', { deep: true, immediate: true })
], default_1.prototype, "updateImagesByClass", null);
default_1 = __decorate([
    Component({ name: 'ImagePreview', components: { ImageComp, ImageDetails } })
], default_1);
export default default_1;
